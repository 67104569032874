import { useEffect, useState } from "react";
import { getMetedataCurrent } from "../../../Service/Meteo/MeteoData";
import DeviceStore from "../../../Store/DeviceStorage";
import { ObservationCurrent } from "../../../Service/Meteo/MeteoDataModel";
import { Device } from "../../../API";

type Props = {
    device: Device
}

function ContentWeatherStation(props: Readonly<Props>) {

    const currendDevice = props.device;
    const [metedata, setMeteData] = useState<ObservationCurrent[]>([])

    useEffect(() => {
        getMetedataCurrent(currendDevice.weatherStationID, currendDevice.weatherStationKey).then(data => {
            setMeteData(data)
        })
    }, [currendDevice])

    console.log(metedata)
    return (
        <div>
            <div className="container">
                {metedata.length > 0 &&
                    <><h2>Dane aktualne</h2><div className="data">
                        <div><label>Czas Obserwacji:</label> {metedata[0].obsTimeUtc}</div>
                        <div><label>Słoneczna Radiaacja:</label> {metedata[0].solarRadiation} W/m²</div>
                        <div><label>Indeks UV:</label> {metedata[0].uv}</div>
                        <div><label>Kierunek Wiatru:</label> {metedata[0].winddir}°</div>
                        <div><label>Wilgotność:</label> {metedata[0].humidity}%</div>
                        <div className="data">
                            <div><label>Temperatura:</label> {metedata[0].metric.temp} °C</div>
                            <div><label>Indeks Ciepła:</label> {metedata[0].metric.heatIndex} °C</div>
                            <div><label>Prędkość Wiatru:</label> {metedata[0].metric.windSpeed} km/h</div>
                            <div><label>Poryw Wiatru:</label> {metedata[0].metric.windGust} km/h</div>
                            <div><label>Tempo opadów:</label> {metedata[0].metric.precipRate} mm/hr</div>
                            <div><label>Opad całkowity:</label> {metedata[0].metric.precipTotal} mm</div>
                        </div>
                    </div></>
                }
            </div>
        </div>
    )
}
export default ContentWeatherStation;



